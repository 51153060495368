
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import authenticationService from "@/core/services/AuthenticationService";
import bookService from "@/app/services/bookService";
import profileService from "@/app/services/profileService";
import eventBus from "@/core/services/eventBus";
import HelpModal from "@/app/components/modals/HelpModal.vue";

const themeColors = [
  {
    name: 'default',
    base: '163C64',
    text: '#597899'
  },
  {
    name: 'ocean',
    base: "046381",
    text: "#4899B2"
  },
  {
    name: 'forest',
    base: "0C5939",
    text: "#4B9778"
  },
  {
    name: 'burgundy',
    base: "820C29",
    text: "#C3788A"
  },
  {
    name: 'purple',
    base: "581D59",
    text: "#597899"
  },
  {
    name: 'pink',
    base: "B23370",
    text: "#D96DA2"
  },
  {
    name: 'dark',
    base: "1E1E1F",
    text: "#626262"
  }
]

const avatars = [
  {name: 'Colors', avatars: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]},
  {name: 'Nature', avatars: [101, 102, 103, 104, 105, 106, 107, 108, 109]},
  {name: 'Animals', avatars: [201, 202, 203, 204, 205, 206, 207, 208, 209, 210]}
];


export default defineComponent({
  name: "profile",
  components: { HelpModal },
  beforeUnmount() {
    eventBus.$off('openHelp');
  },
  data() {
    return {
      avatars,
      displayName: null,
      themeColors,
      toastIsActive: false,
      characterCap: 40
    }
  },
  computed: {
    genreOptions() {
      let hiddenGenres = ['N/A', 'Editorial/Position Piece', 'Exposé', 'Instructional Man.', 'Religion / Spirituality', 'Self Help / Guidance']
      return bookService.getGenres().filter(x => !hiddenGenres.includes(x.name));
    },
    themeColor() {
      /* Responsible for setting check mark on current theme color */
      let baseColor = '163C64';
      if (authenticationService.isLoggedIn()) {
        const colorIndex = authenticationService.currentUser().profile.bg_color;
        if (colorIndex != null) baseColor = colorIndex;
      }
      return {
        baseColor
      };
    },
    getCurrentAvatar() {
      return authenticationService.currentUser().profile.avatar_id
    }
  },
  methods: {
    translatedGenre(genre) {
      let tx = this.$t('genres.'+genre.name.replace(/\./g, ""));
      if (tx.startsWith('genres.')) tx = genre.name;
      return tx;
    },
    toggleGenre(genre) {
      let favorites = profileService.getFavoriteGenres();
      let idx = favorites.indexOf(genre.name);
      if (idx != -1) {
        let faves = favorites.filter(x => x != genre.name);
        profileService.setFavoriteGenres(faves);

      } else {
        favorites.push(genre.name);
        profileService.setFavoriteGenres(favorites);
      }
    },
    isFavoriteGenre(genre) {
      let favorites = profileService.getFavoriteGenres();
      return favorites.indexOf(genre.name) != -1;
    },
    saveChangesPopup() {
      this.toastIsActive = true;
      setTimeout(() => {
        this.toastIsActive = false;
        this.$router.push("home")
      }, 750)
      this.save();

    },
    save() {
      authenticationService.currentUser().profile.display_name = this.displayName;
      authenticationService.saveProfile();
    },
    selectTheme(themeBase) {
      authenticationService.currentUser().profile.bg_color = themeBase;
      this.save();
    },
    selectAvatar(i) {
      authenticationService.currentUser().profile.avatar_id = i;
      this.save();
    },
    avatarSrc(category, i) {
      return "/media/avatars/" + i + ".png";
    }
  },
  mounted() {
    setCurrentPageTitle("My Settings");
    if (authenticationService.isLoggedIn()) {
      this.displayName = authenticationService.currentUser().profile.display_name;
    }
    eventBus.$on('openHelp', () => {
      this.$refs.helpModal.openDialog();
    });
  }
});
