
import { Modal } from "bootstrap";
export default {
  props: ['title'],
  data() {
    return {}
  },
  methods: {
    openDialog() {
      this.dialog = new Modal(this.$refs.helpDialog);
      this.dialog.show();
    },
    closeDialog() {
      if (this.dialog)
        this.dialog.hide();
    }
  }
}
