import authenticationService from "@/core/services/AuthenticationService";

const profileService = {
    getFavoriteGenres() {
        if (authenticationService.isLoggedIn() == false) return [];
        return authenticationService.getSetting('genres') || [];
    },
    setFavoriteGenres(genres) {
        authenticationService.changeSetting('genres', genres);
    },
};

export default profileService;