import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "page-profile" }
const _hoisted_2 = { class: "card bg-light" }
const _hoisted_3 = { class: "card-body profile-card-wrapper" }
const _hoisted_4 = { class: "row mb-5" }
const _hoisted_5 = { class: "col-sm-12 col-md-4" }
const _hoisted_6 = { class: "card bg-white" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { for: "displayName" }
const _hoisted_9 = ["maxlength"]
const _hoisted_10 = { class: "col-sm-12 col-md-8" }
const _hoisted_11 = { class: "card bg-white h-100" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "w-100 d-flex flex-wrap justify-content-sm-between mt-3" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["alt"]
const _hoisted_16 = { class: "row mb-5" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "card bg-white" }
const _hoisted_19 = { class: "card-body" }
const _hoisted_20 = { class: "avatar-icon-wrapper" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["src", "alt"]
const _hoisted_23 = { class: "row mb-5" }
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "card bg-white genre-selection" }
const _hoisted_26 = { class: "card-body" }
const _hoisted_27 = { class: "genre-checkboxes" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = ["aria-label", "checked"]
const _hoisted_30 = { class: "genre-name" }
const _hoisted_31 = { class: "profile-footer d-flex align-items-center" }
const _hoisted_32 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_help_modal = _resolveComponent("help-modal")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_help_modal, { ref: "helpModal" }, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('pages.profile.heading')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('pages.profile.displayNameLabel')), 1)
                ]),
                _withDirectives(_createElementVNode("input", {
                  id: "displayName",
                  class: "form-control",
                  type: "text",
                  spellcheck: "false",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayName) = $event)),
                  onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                  maxlength: _ctx.characterCap
                }, null, 40, _hoisted_9), [
                  [_vModelText, _ctx.displayName]
                ]),
                _createElementVNode("small", null, [
                  _createElementVNode("span", {
                    style: _normalizeStyle({color: _ctx.displayName?.length > (_ctx.characterCap * 0.75) ? 'red' : 'green'})
                  }, _toDisplayString(_ctx.characterCap - _ctx.displayName?.length), 5),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('pages.profile.charactersRemaining')), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('pages.profile.pickTheme')), 1),
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themeColors, (theme) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: theme,
                      onClick: ($event: any) => (_ctx.selectTheme(theme.base)),
                      class: "theme-color d-flex justify-content-center align-items-center m-1",
                      style: _normalizeStyle({backgroundColor: '#'+theme.base})
                    }, [
                      (theme.base === _ctx.themeColor.baseColor )
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: "media/icons/check-icon.svg",
                            alt: theme.base + 'theme color'
                          }, null, 8, _hoisted_15))
                        : _createCommentVNode("", true)
                    ], 12, _hoisted_14))
                  }), 128))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('pages.profile.chooseAvatar')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (category) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: category,
                    class: "avatar-category"
                  }, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('pages.profile.category.' + category.name)), 1),
                    _createElementVNode("div", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.avatars, (avatar) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: avatar,
                          class: "avatar-option",
                          style: _normalizeStyle({outline: avatar === _ctx.getCurrentAvatar ? 'solid 8px #E5E5E5' : _ctx.none}),
                          onClick: ($event: any) => (_ctx.selectAvatar(avatar))
                        }, [
                          _createElementVNode("img", {
                            src: _ctx.avatarSrc(category, avatar),
                            alt: 'avatar ' + avatar
                          }, null, 8, _hoisted_22)
                        ], 12, _hoisted_21))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('pages.profile.genreHeading')), 1),
                _createElementVNode("div", _hoisted_27, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genreOptions, (genre) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["checkbox", {active: _ctx.isFavoriteGenre(genre)}]),
                      key: genre,
                      onClick: ($event: any) => (_ctx.toggleGenre(genre))
                    }, [
                      _createElementVNode("input", {
                        class: "checkbox-input",
                        type: "checkbox",
                        "aria-label": genre.name,
                        checked: _ctx.isFavoriteGenre(genre)
                      }, null, 8, _hoisted_29),
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_inline_svg, {
                          src: "/media/icons/check-icon.svg",
                          class: "check-icon",
                          alt: "Check Icon"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.translatedGenre(genre)), 1)
                      ])
                    ], 10, _hoisted_28))
                  }), 128))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("button", {
            type: "button",
            class: "btn mr-5",
            style: {backgroundColor: '#1D5CFE', color: '#fff', whiteSpace: 'nowrap'},
            disabled: _ctx.toastIsActive,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveChangesPopup && _ctx.saveChangesPopup(...args)))
          }, _toDisplayString(_ctx.$t('pages.profile.saveAndGo')), 9, _hoisted_32),
          _createElementVNode("h1", {
            class: _normalizeClass(["saved-toast", {active: _ctx.toastIsActive}])
          }, _toDisplayString(_ctx.$t('pages.profile.saving')), 3)
        ])
      ])
    ])
  ]))
}